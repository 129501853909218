function addAni() {
  let panels = document.getElementsByTagName("section");
  for (let i = 1; i < panels.length; i++) {
    let p = panels[i];
    let scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    let offsetTop = p.offsetTop;
    let clientHeight = document.body.clientHeight;
    let keyValue = 8500;
    let scaleValue = 100;
    if (getRatio() == 100) {
      scaleValue = 100;
      keyValue = 8500;
    } else if (getRatio() == 125) {
      scaleValue = 180;
    } else if (getRatio() == 150) {
      keyValue = 7500;
      scaleValue = 100;
    }
    if (scrollTop > scaleValue) {
      document.getElementById("head").style.opacity = ".5";
    } else {
      document.getElementById("head").style.opacity = "1";
    }
    if (scrollTop + clientHeight - keyValue > offsetTop) {
      document.getElementById("goTop").style.display = "block";
      p.classList.add("build-in-animate");
    } else {
      p.classList.remove("build-in-animate");
      document.getElementById("goTop").style.display = "none";
    }
  }
}
function goTop() {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
}
function getRatio() {
  var ratio = 0;
  var screen = window.screen;
  var ua = navigator.userAgent.toLowerCase();
  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  } else if (~ua.indexOf("msie")) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  } else if (
    window.outerWidth !== undefined &&
    window.innerWidth !== undefined
  ) {
    ratio = window.outerWidth / window.innerWidth;
  }
  if (ratio) {
    ratio = Math.round(ratio * 100);
  }
  return ratio;
}
// 高德地图key
window._AMapSecurityConfig = {
  securityJsCode: "0dbaea854def325d23d65e4e8bd4de9e",
};
AMapLoader.load({
  key: "171ea2206fe44370aaeabfcc2994b885", // 申请好的Web端开发者Key，首次调用 load 时必填
  version: "1.4.4", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
})
  .then((AMap) => {
    const map = new AMap.Map("container", {
      viewMode: "3D",
      pitch: 50,
      zooms: [10, 20],
      zoom: 20,
      center: [118.735716, 31.982378],
    });

    //构建信息窗体中显示的内容
    var info = [];
    info.push(
      `<div class="address">
      <img style="flow:left;width:30px;margin: 0 20px" src="https://yytek-bucket.oss-cn-shanghai.aliyuncs.com/prod/csi-saas/companySite/770a7b6c4e8ffb8dba007c14d881869.png"/>
      <span>南京沅宇科技有限公司</span>
      </div>
      <div class='address-content'>
      <div><img style="flow:left;width:20px;margin-right:10px" src="https://yytek-bucket.oss-cn-shanghai.aliyuncs.com/prod/csi-saas/companySite/95002aab10babbf1e69d01c72ab8dba.png"/><span>025-83337177</span></div>
      <div><img style="flow:left;width:20px;margin-right:10px" src="https://yytek-bucket.oss-cn-shanghai.aliyuncs.com/prod/csi-saas/companySite/6abd29e01adf98146dc9f6ba2e4001b.png"/><span>15371016913@163.com</span></div>
      <div><img style="flow:left;width:20px;margin-right:10px" src="https://yytek-bucket.oss-cn-shanghai.aliyuncs.com/prod/csi-saas/companySite/450f0ffc7042f097ef9c5b746010c56.png"/><span>江苏省南京市建邺区嘉陵江东街18号 — 4栋905</span></div>
     </div>
     `
    );
    infoWindow = new AMap.InfoWindow({
      content: info.join("<br/>"),
      offset: new AMap.Pixel(0, -35),
    });
    infoWindow.open(map, map.getCenter());
    var icon = new AMap.Icon({
      size: new AMap.Size(20, 30),
      image: require("../img/position.png"),
      imageSize: new AMap.Size(20, 28),
    });
    const marker = new AMap.Marker({
      position: [118.735716, 31.982378],
      icon: icon,
    });
    map.add(marker);
  })
  .catch((e) => {
    console.error(e); //加载错误提示
  });

window.addEventListener("scroll", addAni);
document.getElementById("goTop").addEventListener("click", goTop);

document.getElementById("menuOne").addEventListener("click", function () {
  if (getRatio() == 150) {
    document.documentElement.scrollTop = document.body.scrollTop = 600;
  } else {
    document.documentElement.scrollTop = document.body.scrollTop = 900;
  }
});
document.getElementById("menuTwo").addEventListener("click", function () {
  if (getRatio() == 150) {
    document.documentElement.scrollTop = document.body.scrollTop = 1920;
  } else {
    document.documentElement.scrollTop = document.body.scrollTop = 2550;
  }
});
document.getElementById("menuThree").addEventListener("click", function () {
  if (getRatio() == 150) {
    document.documentElement.scrollTop = document.body.scrollTop = 6350;
  } else {
    document.documentElement.scrollTop = document.body.scrollTop = 7350;
  }
});

addAni();
